<template>
  <el-dialog :title="title"
             :close-on-click-modal="false"
             append-to-body
             :visible.sync="visible">
    <el-table :data="tableData"
              style="width: 100%"
              height="450">
      <el-table-column fixed
                       prop="dictValue"
                       label="参数"
                       width="150" />
      <el-table-column label="内容填写">
        <template slot-scope="scope">
          <el-input placeholder="请输入内容"
                    v-model="scope.row.value"
                    clearable>
          </el-input>
        </template>
      </el-table-column>

    </el-table>
    <span slot="footer"
          class="dialog-footer">
      <el-button size="small"
                 @click="visible = false">取消</el-button>
      <el-button size="small"
                 type="primary"
                 @click="doSubmit()"
                 v-noMoreClick>确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  declarationFormTemplateReleaseAuthorityListApi,
  declarationFormTemplateReleaseAuthorityListSaveApi,
} from '@/api/declaration/declarationFormTemplate'
export default {
  data() {
    return {
      visible: false,
      loading: false,
      title: '',
      tableData: [],
      rowId: '',
      type: '',
    }
  },
  methods: {
    /**
     * 初始化
     */
    init(templateId) {
      declarationFormTemplateReleaseAuthorityListApi(templateId).then((res) => {
        if (res && res.status) {
          this.tableData = res.data
          this.visible = true
        }
        console.log(res)
      })
    },

    doSubmit() {
      declarationFormTemplateReleaseAuthorityListSaveApi(this.tableData).then(
        (res) => {
          if (res && res.status) {
            this.$message.success('提交成功')
            this.visible = false
          } else {
            this.$message.success('提交失败')
          }
        }
      )
    },
  },
}
</script>

<style>
</style>