<template>
  <div class="page bg-white" id="reorganize">
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="lump">
          <div class="catalogueBox">
            <div class="flex_l_c">
              <div class="f_w">展览：</div>
              <el-input class="flex1" maxlength="66" v-model="catalogueSrh" placeholder="请输入展览名称" size="small" clearable></el-input>
            </div>
            <el-table :data="catalogueList" ref="catalogueTable" size="small" height="calc(100vh - 268px)" class="table"
              highlight-current-row @current-change="handleCurrentChange">
              <el-table-column prop="exhibitionName" label="展览" show-overflow-tooltip />
            </el-table>
            <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
              :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
              layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>
      </el-col>
      <el-col :span="18">
        <div class="lump">
          <div class="flex_b_c headBox">
            <div class="flex_l_c">
              <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick :disabled="saveFlag">保存</el-button>
            </div>
          </div>
          <div class="catalogueBox">
            <div class="flex_b_c">
              <div class="f_w">申报书填写清单</div>
              <div>
              </div>
            </div>
            <el-table :data="tableData.filter(data => !search || data.dictValue.toLowerCase().includes(search.toLowerCase()))" style="width: 100%" height="calc(100vh - 258px)">
              <el-table-column fixed prop="dictValue" label="参数" width="150" />
              <el-table-column label="内容填写" width="450">
                <template slot-scope="scope">
                  <el-input :placeholder="scope.row.edit === null ? '' : '请输入内容'" type="textarea" v-model="scope.row.value" style="width: 100%"
                    :disabled="scope.row.edit === null" maxlength="500" clearable  :rows="3" resize="none">
                  </el-input>
                </template>
              </el-table-column>
              <!-- <el-table-column label="权限">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.readOnly!==null"
                          type="success">查看</el-tag>
                  <el-tag v-if="scope.row.edit!==null"
                          type="info">编辑</el-tag>
                </template>
              </el-table-column> -->
               <el-table-column>
      <template slot="header" slot-scope="scope">
        <el-input
          v-model="search"
          size="mini"
          placeholder="输入关键字搜索"/>
      </template>
    </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>
    <declarationAssignFill ref="declarationAssignFill"></declarationAssignFill>
  </div>
</template>

<script>
import {
  declarationFormTemplateReleaseListApi,
  declarationFormTemplateReleaseAuthorityListSaveApi,
  declarationFormTemplateReleaseAuthorityListApi,
} from '@/api/declaration/declarationFormTemplate'

import declarationAssignFill from './DeclarationAssignFill' // 新建弹窗
export default {
  components: { declarationAssignFill },
  data() {
    return {
      visibleLog: false,
      // 展览
      catalogueSrh: '',
      catalogueList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,search:'',
      // 文物清单
      fileSrh: '',
      fileList: [],
      dataListSelections: [],
      loading: false,
      pageNo2: 1,
      pageSize2: 10,
      total2: 0,
      moduleId: 1, // 当前请求拖拽排序数据id
      setShow: false, // 是否显示列表设置拖拽排序弹窗
      exhibitionId: '',
      log: {
        operModular: '3', //模块
        operType: '1', //类型
        operTerm: '', //内容
        operSystem: '1', //结果
      },
      config: [],
      rows: [],
      row: {},
      tableData: [],
      saveFlag:true,
    }
  },
  watch: {
    // 实时输入查询目录
    catalogueSrh() {
      this.searchCatalogue()
    },
  },
  mounted() {
    this.getCatalogue()
    //this.getFileList()
    this.querySysOriginalDescriptionAll()
  },
  methods: {
    querySysOriginalDescriptionAll() {
      let that = this
      this.$axios(
        this.api.catalogue.querySysOriginalDescriptionAll,
        {
          flag: 1,
          onDetails: 1,
        },
        'get'
      ).then((data) => {
        if (data) {
          this.config = data
          this.config.forEach(function (v) {
            v.fieldEnname = that.toHump(v.fieldEnname)
            if (v.fieldEnname == 'updateId') {
              v.fieldEnname = 'updateName'
            }
            if (v.fieldEnname == 'createId') {
              v.fieldEnname = 'createName'
            }
            // that.$set(that.inputForm,v.fieldEnname,"")
          })
          console.log('配置：', this.config)
        }
      })
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase()
      })
    },
    /**
     * 内容填写
     */
    fillIn(row) {
      this.$refs.declarationAssignFill.init(row.id)
    },
    // 文物清单表格多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    searchCatalogue() {
      this.pageNo = 1
      this.pageSize = 10
      this.total = 0
      this.exhibitionId = ''
      this.getCatalogue()
    },
    // 查询展览
    getCatalogue() {
      this.$axios(
        this.api.infor.informationList,
        {
          exhibitionName: this.catalogueSrh,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        },
        'get'
      ).then((data) => {
        if (data && data.status) {
          this.catalogueList = data.data.records
          this.total = parseInt(data.data.total)
        }
      })
    },
    // 点击展览行
    handleCurrentChange(val) {
      console.log(val)
      this.saveFlag=false
      this.$refs.catalogueTable.setCurrentRow(val)
      this.exhibitionId = val.id
      this.getFileList(val.id)
    },
    // 查询申报书清单
    getFileList(id) {
      this.loading = true
      declarationFormTemplateReleaseAuthorityListApi(id).then((res) => {
        if (res && res.status) {
          this.tableData = res.data
          this.loading = false
        } else {
          this.tableData = []
          this.loading
          this.$message.success(res.msg)
        }
        console.log(res)
      })
    },
    // 下载
    download(id) {
      console.log(id)
    },
    // 设置
    setTb() {
      this.setShow = true
    },
    // 接收子组件（排序弹窗）传过来的值
    getTbList(data, state) {
      this.fileList = data
      this.getFileList()
      this.setShow = state
    },
    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.getCatalogue()
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.getCatalogue()
    },
    // 文物清单每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val
      this.pageNo2 = 1
      this.getFileList()
    },
    // 文物清单当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val
      this.getFileList()
    },
    doSubmit() {
      declarationFormTemplateReleaseAuthorityListSaveApi(this.tableData).then(
        (res) => {
          if (res && res.status) {
            this.$message.success('提交成功')
            this.visible = false
          } else {
            this.$message.success('提交失败')
          }
        }
      )
    },
  },
}
</script>

<style scoped>
</style>
